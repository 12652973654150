import * as React from 'react';

import { Script } from 'strat/util';

const ONE_TAP_SCRIPT_URL = 'https://accounts.google.com/gsi/client';

type KeycloakGoogleOneTapCallback = (arg1: { credential: string; select_by: string }) => void;

const useKeycloakGoogleOneTapEffect = (
    show: boolean,
    clientID: string | null | undefined,
    callback: KeycloakGoogleOneTapCallback,
): void =>
    React.useEffect(() => {
        Script.load(ONE_TAP_SCRIPT_URL).then(() => {
            try {
                if (!google) {
                    // This must mean that Google One Tap is not supported in this browser.
                    // https://developers.google.com/identity/gsi/web/guides/supported-browsers
                    return;
                }
            } catch (e: any) {
                // google might be not defined
                // https://sentry.io/organizations/sc-sector-labs-srl/issues/3207915598
                return;
            }

            if (show && !!clientID) {
                google.accounts.id.initialize({
                    client_id: clientID,
                    callback,
                    cancel_on_tap_outside: false,
                    use_fedcm_for_prompt: true,
                });

                google.accounts.id.prompt();
            } else {
                google.accounts.id.cancel();
            }
        });
    }, [clientID, callback, show]);

export default useKeycloakGoogleOneTapEffect;
