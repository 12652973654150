import { t } from '@lingui/macro';
import * as React from 'react';
import { useLoginWithTokenExchange } from '@sector-labs/fe-auth-redux/thunk';
import type { KeycloakIdentityProvider } from '@sector-labs/fe-auth-redux/keycloak-client';
import {
    useHasActiveSession,
    useStartLoginAttempt,
    useResetSession,
} from '@sector-labs/fe-auth-redux/state';

import { useI18n } from 'strat/i18n/language';
import { InternalErrorDialog } from 'strat/error';

import useKeycloakErrorData from '../hooks/useKeycloakErrorData';
import { useKeycloakSiteConfigWithCallbacks } from '../hooks/useKeycloakSiteConfig';
import useKeycloakGoogleOneTapEffect from '../hooks/useKeycloakGoogleOneTapEffect';

/**
 * Provides the Google One-Tap sign-in widget.
 *
 * https://developers.google.com/identity/one-tap/web
 *
 * Google One-Tap provides only authentication. After
 * the user has authenticated, we have to authorize.
 * If it is the first time the user logs in, a prompt
 * will be served asking the user for permission.
 *
 * After we've been authorized, we have a Google access
 * token. We exchange this with our own auth server (Keycloak)
 * for a set tokens to be used with our application.
 */
const KeycloakGoogleOneTap = () => {
    const i18n = useI18n();

    const startLoginAttempt = useStartLoginAttempt();
    const resetSession = useResetSession();

    const clientID = CONFIG.build.GOOGLE_OAUTH_CLIENT_ID || null;

    const [googleError, setGoogleError] = React.useState<any>(null);

    const [authErrorData, resetAuthError] = useKeycloakErrorData();
    const [loginAttempted, setLoginAttempted] = React.useState(false);

    const siteConfig = useKeycloakSiteConfigWithCallbacks();
    const loginWithTokenExchange = useLoginWithTokenExchange();

    const hasActiveSession = useHasActiveSession(siteConfig.keycloakConfig);

    const reset = React.useCallback(() => {
        resetSession();
        setGoogleError(null);
        resetAuthError();
        setLoginAttempted(false);
    }, [resetSession, setGoogleError, resetAuthError]);

    useKeycloakGoogleOneTapEffect(
        !hasActiveSession && !!clientID,
        clientID,
        React.useCallback(
            ({ credential: idToken }) => {
                startLoginAttempt();
                setLoginAttempted(true);

                try {
                    loginWithTokenExchange(siteConfig, {
                        provider: 'google' as KeycloakIdentityProvider,
                        idToken,
                    });
                } catch (err: any) {
                    setGoogleError(err);
                    resetSession();
                }
            },
            [siteConfig, loginWithTokenExchange, resetSession, startLoginAttempt],
        ),
    );

    return (
        <>
            <InternalErrorDialog
                visible={loginAttempted && (!!googleError || !!authErrorData?.message)}
                onVisibilityChanged={reset}
                message={
                    authErrorData?.message ||
                    t(i18n)`We're having trouble interacting with Google. Please try again later.`
                }
            />
        </>
    );
};

export default KeycloakGoogleOneTap;
